$screen-xs: 359px;
$screen-xs-min: $screen-xs;
$screen-phone: $screen-xs-min;
$screen-sm: 769px;
$screen-sm-min: $screen-sm;
$screen-tablet: $screen-sm-min;
$screen-md: 992px;
$screen-md-min: $screen-md;
$screen-desktop: $screen-md-min;
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;
$screen-lg-desktop: $screen-lg-min;
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$grid-float-breakpoint: $screen-sm-min !default;
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;
$box-shadow: 0 1px 10px rgba(0, 0, 0, 0.04);
$border-radius: 3px;

* {
  box-sizing: border-box;
}

html {
  font-size: 13px;
}

@font-face {
  font-family: 'IBM Plex Sans JP';
  src: url('../font/IBMPlexSansJP-Regular.ttf') format('opentype'),
  url('../font/IBMPlexSansJP-Medium.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans JP';
  src: url('../font/IBMPlexSansJP-Bold.ttf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@media (min-width: $grid-float-breakpoint) {

  body {
    margin: 0;
    padding: 0;
    font-family: 'IBM Plex Sans JP',sans-serif;

    .wrap {
      position: relative;
      margin: 0 auto;
      min-height: 100vh;

      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 17px;
        height: 100%;
        top: 0;
        left: 0;
        background: url("../img/bg_left.svg") repeat;
      }

      &::after {
        content: '';
        position: absolute;
        display: block;
        width: 17px;
        height: 100%;
        bottom: 300px;
        right: 0;
        background: url("../img/bg_right.svg") repeat;
        background-position: right 300px;
      }

      .bg {
        width: 100%;
        height: 100%;
        padding: 0 100px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;

        .bg_inner {
          width: 100%;
          height: 100%;
          position: relative;

          .bg_line {
            width: 100%;
            height: 100%;
            position: relative;

            div {
              width: 1px;
              height: 100%;
              position: absolute;
              top: 0;
              overflow: hidden;
              margin: 0 0 0 -1px;

              span {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(245,245,245,1);
              }

              &:nth-child(1) {
                left: 0;
                margin: 0;
              }
              &:nth-child(2) {
                left: 25%;
              }
              &:nth-child(3) {
                left: 50%;
              }
              &:nth-child(4) {
                left: 75%;
              }
              &:nth-child(5) {
                left: 100%;
              }
            }
          }
        }
      }

      .bg_top {
        width: 100%;
        min-height: 100vh;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 1;

        &:before {
          content: '';
          position: absolute;
          display: block;
          width: 700px;
          height: 17px;
          top: 0;
          left: 0;
          background: #f45b5b;
        }

        &::after {
          content: '';
          position: absolute;
          display: block;
          width: 700px;
          height: 17px;
          bottom: 0;
          right: 0;
          background: #f45b5b;
        }
      }

      .inner {
        max-width: 1120px;
        padding: 80px 60px 57px;
        margin: 0 auto;
        position: relative;
        z-index: 3;

        .info {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          padding: 0;
          text-decoration: none;
          border: solid 2px #333;
          background: #fff;
          position: relative;
          position: fixed;
          right: 40px;
          bottom: 50px;
          box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.05);
          cursor: pointer;

          &:before {
            content: '';
            display: block;
            position: absolute;
            width: 12px;
            height: 20px;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            transition: transform 0.3s;
            transform-origin: center;
            background: url("../img/icon-question.svg") no-repeat center center;
          }

          &:hover {
            &:before {
              transform: scale(1.2);
            }
          }
        }

        .info-detail {
          width: 300px;
          height: 380px;
          position: fixed;
          bottom: 40px;
          right: 40px;
          background: #f8f8f8;
          box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.05);
          padding: 0 0 15px;
          border-radius: 10px;

          .info-title {
            padding: 14px 25px 12px;
            background: #56B7C4;
            border-radius: 10px 10px 0 0;
            position: relative;

            .info-close {
              display: block;
              position: absolute;
              cursor: pointer;
              width: 15px;
              height: 15px;
              bottom: 0;
              top: 0;
              right: 25px;
              margin: auto;
              &:before,
              &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                width: 2.5px;
                height: 20px;
                background: #fff;
              }
              &:before {
                transform: translate(-50%,-50%) rotate(45deg);
              }
              &:after {
                transform: translate(-50%,-50%) rotate(-45deg);
              }
            }

            h2 {
              margin: 0;
              padding: 0;
              display: flex;
              align-items: center;
              height: 100%;
              font-size: 16px;
              line-height: 19px;
              color: #fff;
            }
          }

          .info-description {
            max-height: 285px;
            margin: 25px 0;
            padding: 0 25px;
            overflow: auto;
            border-radius: 0 0 10px 10px;

            p {
              margin: 0 0 25px;
              font-size: 13px;
              line-height: 1.5;
              color: #333;
              &:last-of-type {
                margin: 0;
              }
            }
          }
        }

        .mv {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .logo {
            margin: 0 25px 0 0;

            img {
              width: 100%;
            }
          }

          .text {
            font-size: 20px;
            font-weight: bold;
            padding: 10px 0;
            color: #333;

            p {
              margin: 0;
              padding: 0;
            }
          }
        }

        form {
          margin: 0;
          padding: 0;
          text-align: center;

          .text_area {
            margin: 50px 0;
            display: flex;

            .area {
              width: 50%;
              margin: 0 20px 0 0;
              text-align: left;

              &:last-child {
                margin: 0;
              }

              label {
                font-size: 16px;
                font-weight: bold;
                text-align: left;
                color: #333;
                display: block;
                margin: 0 0 20px;
              }

              textarea {
                width: 100%;
                height: 300px;
                padding: 15px;
                font-family: inherit;
                font-size: 13px;
                border: 10px solid #efefef;
                box-sizing:border-box;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
              }

              .print_text1,
              .print_text2 {
                display: none;
              }
            }
          }

          .button {
            text-align: center;
            display: inline-block;

            button {
              width: 316px;
              height: 90px;
              font-size: 22px;
              font-weight: bold;
              font-family: inherit;
              padding: 0 70px 0 50px;
              color: #fff;
              position: relative;
              text-align: center;
              background-color: #56b7c4;
              border: 1px solid #56b7c4;
              border-radius: 45px;
              text-decoration: none;
              cursor: pointer;
              transition: background-color 1s;

              .load_icon {
                position: relative;
                float: right;
                width: 36px;
                height: 33px;
                background: url("../img/icon.svg") no-repeat;
                background-size: contain;
                transition: all 0.25s ease;
              }

              .move_icon {
                display: none;
              }
            }

            .doing .move_icon {
              display: block;
              position: relative;
              float: right;
              width: 36px;
              height: 33px;

              &:before,
              &::after {
                content: "";
                display: block;
                position: absolute;
                width: 21px;
                height: 28px;
                animation:part-anim 0.8s cubic-bezier(0.445, 0.050, 0.550, 0.950) infinite alternate;
              }

              &:before {
                animation-direction:alternate-reverse;
                background: url("../img/icon-yellow.svg") no-repeat;
                top: 6.5px;
                left: 8px;
                background-size: contain;
              }

              &::after {
                background: url("../img/icon-red.svg") no-repeat;
                background-size: contain;
                top: 0.5px;
                left: 7px;
              }

              @keyframes part-anim{
                0%{
                  transform:translate3d(-10px,0,0);
                }
                100%{
                  transform:translate3d(10px,0,0);
                }
              }
            }

            .doing .load_icon {
              display: none;
            }

            &:hover {
              .load_icon {
                transform:scale(1.2,1.2);
              }
            }
          }

          .nothing {
            margin: 60px 0 130px;
            display: none;
            font-size: 16px;
            font-weight: bold;
          }

          .active {
            display: block;
          }

          .result {
            display: flex;
            margin: 50px 0 0;

            .area {
              width: 50%;
              margin: 0 20px 0 0;
              text-align: left;
              position: relative;

              &::after {
                content: '';
                position: absolute;
                width: 10px;
                height: calc(100% - 45px);
                background: #efefef;
                top: 45px;
                left: 0;
              }

              &:last-child {
                margin: 0;
              }

              h3 {
                font-size: 16px;
                font-weight: bold;
                color: #333;
                display: block;
                margin: 0 0 20px;
              }

              .result_area {
                background: #fff;
                position: relative;
                padding: 0 20px;

                .omit {
                  height: 21px;
                  background: #efefef;
                }

                .wrap {
                  display: flex;
                  margin: 2px 0;
                  min-height: 20px;

                  &::before,
                  &::after {
                    content: none;
                  }

                  p {
                    max-width: 415px;
                    margin: 0 0 0 5px;
                    white-space: pre-wrap;
                    word-wrap: break-word;
                    overflow: auto;
                  }
                  .number {
                    min-width: 45px;
                    text-align: right;
                    padding: 0 10px 0 0;
                    margin: 0;
                    position: relative;
                    color: #c1c1c1;

                    div {
                      margin: 0;
                      display: inline-block;
                      color: #777;
                    }
                  }
                }

                .deleted {
                  text-decoration: none;
                  font-weight: bold;
                  position: relative;
                  z-index: 0;

                  &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    left: 0;
                    right: 0;
                    top: 2px;
                    bottom: 2px;
                    background: rgba(244, 91, 91, 0.2);
                    z-index: -1;
                  }
                }

                .added {
                  text-decoration: none;
                  font-weight: bold;
                  position: relative;
                  z-index: 0;

                  &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    left: 0;
                    right: 0;
                    top: 2px;
                    bottom: 2px;
                    background: rgba(86, 183, 196, 0.2);
                    z-index: -1;
                  }
                }

                .has_diff {
                  text-decoration: none;
                  font-weight: bold;
                  position: relative;
                  z-index: 0;

                  .wrap {
                    display: flex;
                  }

                  &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background: rgba(255, 186, 0, 0.2);
                    z-index: -1;
                  }
                }

                ins,
                del {
                  text-decoration: none;
                  font-weight: bold;
                  z-index: 0;
                  display: inline;
                  background: rgba(255, 186, 0, 0.6);
                }
              }
            }
          }

          .share {
            width: 128px;
            margin: 50px auto;

            p {
              font-size: 16px;
              color: #333;
              font-weight: bold;
              text-align: center;
              padding: 0 0 0 5px;
              margin: 0 0 20px;
              position: relative;

              &:before {
                content: '';
                position: absolute;
                display: block;
                background: #333;
                width: 2px;
                height: 24px;
                top: 0;
                left: 0;
                transform: rotate(-25deg);
              }

              &::after {
                content: '';
                position: absolute;
                display: block;
                background: #333;
                width: 2px;
                height: 24px;
                top: 0;
                right: 0;
                transform: rotate(25deg);
              }
            }

            .btn {
              display: flex;
              width: 116px;
              margin: 0 auto;
              justify-content: space-between;

              a {
                width: 48px;
                height: 48px;
                background: #fff;
                border-radius: 50%;
              }

              .twitter {
                position: relative;
                .active-tw {
                  position: absolute;
                  left: 0;
                  top: 0;
                  opacity: 0;
                  transition: 0.5s;
                }

                &:hover {
                  .active-tw {
                    opacity: 1;
                  }
                }
              }
              .facebook {
                position: relative;
                .active-fb {
                  position: absolute;
                  left: 0;
                  top: 0;
                  opacity: 0;
                  transition: 0.5s;
                }

                &:hover {
                  .active-fb {
                    opacity: 1;
                  }
                }
              }
            }
          }

          .ad-box {
            margin: 50px auto;
            max-width: 100%;
            overflow: hidden;
          }

          .copyright {
            text-align: center;
            margin: 75px 0 0;
            font-size: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: $grid-float-breakpoint-max) {
  body {
    margin: 0;
    padding: 0;
    font-family: IBM Plex Sans JP,sans-serif;

    .wrap {
      background: #fff;
      margin: 0 auto;
      position: relative;
      min-height: 100vh;

      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 8.5px;
        height: 100%;
        top: 0;
        left: 0;
        background: url("../img/bg_left.svg") repeat;
      }

      &::after {
        content: '';
        position: absolute;
        display: block;
        width: 8.5px;
        height: 100%;
        bottom: 300px;
        right: 0;
        background: url("../img/bg_right.svg") repeat;
        background-position: right 300px;
      }

      .bg_top {
        width: 100%;
        padding: 0 30px;
        min-height: 100vh;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 1;

        &:before {
          content: '';
          position: absolute;
          display: block;
          width: 50%;
          height: 8.5px;
          top: 0;
          left: 0;
          background: #f45b5b;
        }

        &::after {
          content: '';
          position: absolute;
          display: block;
          width: 50%;
          height: 8.5px;
          bottom: 0;
          right: 0;
          background: #f45b5b;
        }
      }

      .bg {
        width: 100%;
        height: 100%;
        padding: 0 30px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;

        .bg_inner {
          width: 100%;
          height: 100%;
          position: relative;

          .bg_line {
            width: 100%;
            height: 100%;
            position: relative;

            div {
              width: 1px;
              height: 100%;
              position: absolute;
              top: 0;
              overflow: hidden;
              margin: 0 0 0 -1px;

              span {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(245,245,245,1);
              }

              &:nth-child(1) {
                left: 0;
                margin: 0;
              }
              &:nth-child(2) {
                left: calc(100%/3);
              }
              &:nth-child(3) {
                left: calc(100%/3*2);
              }
              &:nth-child(4) {
                left: 100%;
              }
              &:nth-child(5) {
                display: none;
              }
            }
          }
        }
      }

      .inner {
        padding: 80px 50px 57px;
        position: relative;
        z-index: 3;

        .info {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          padding: 0;
          text-decoration: none;
          border: solid 2px #333;
          background: #fff;
          position: relative;
          position: fixed;
          right: 20px;
          bottom: 30px;
          box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.05);
          cursor: pointer;

          &:before {
            content: '';
            display: block;
            position: absolute;
            width: 12px;
            height: 20px;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            background: url("../img/icon-question.svg") no-repeat center center;
          }
        }

        .info-detail {
          width: 100%;
          height: 100%;
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: #f8f8f8;
          padding: 0 0 15px;

          .info-title {
            padding: 22px 25px 18px;
            background: #56B7C4;
            position: relative;

            .info-close {
              display: block;
              position: absolute;
              cursor: pointer;
              width: 15px;
              height: 15px;
              bottom: 0;
              top: 0;
              right: 25px;
              margin: auto;
              &:before,
              &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                width: 2.5px;
                height: 20px;
                background: #fff;
              }
              &:before {
                transform: translate(-50%,-50%) rotate(45deg);
              }
              &:after {
                transform: translate(-50%,-50%) rotate(-45deg);
              }
            }

            h2 {
              margin: 0;
              padding: 0;
              vertical-align: middle;
              display: inline-block;
              font-size: 16px;
              line-height: 1;
              color: #fff;
            }
          }

          .info-description {
            max-height: 500px;
            margin: 25px 0;
            padding: 0 25px;
            overflow: auto;
            border-radius: 0 0 10px 10px;

            p {
              margin: 0 0 25px;
              font-size: 13px;
              line-height: 1.5;
              color: #333;
              &:last-of-type {
                margin: 0;
              }
            }
          }
        }

        .mv {
          display: block;

          .logo {
            max-width: 400px;

            img {
              width: 100%;
            }
          }

          .text {
            font-size: 20px;
            font-weight: bold;
            color: #333;
            margin: 50px 0;
          }
        }

        form {
          margin: 0;
          padding: 0;
          text-align: center;

          .text_area {
            margin: 0;
            display: block;

            .area {
              width: 100%;
              margin: 0 20px 0 0;
              text-align: left;

              &:last-child {
                margin: 0;
              }

              label {
                font-size: 16px;
                font-weight: bold;
                text-align: left;
                color: #333;
                display: block;
                margin: 20px 0;
              }

              textarea {
                width: 100%;
                height: 300px;
                padding: 15px;
                font-family: inherit;
                font-size: 13px;
                border: 10px solid #efefef;
                box-sizing:border-box;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
              }

              .print_text1,
              .print_text2 {
                display: none;
              }
            }
          }

          .button {
            padding: 50px 0 0;
            text-align: center;
            display: inline-block;

            .doing {
              position: relative;

              &:after {
                content: none;
              }
            }

            button {
              width: 220px;
              height: 67px;
              line-height: 67px;
              font-size: 22px;
              font-family: inherit;
              font-weight: bold;
              padding: 0 15px 0 25px;
              color: #fff;
              position: relative;
              text-align: left;
              background-color: #56b7c4;
              border: 1px solid #56b7c4;
              border-radius: 33.5px;
              text-decoration: none;
              cursor: pointer;
              transition: background-color 1s;


              &::after {
                content: '';
                position: absolute;
                display: block;
                width: 60px;
                height: 32px;
                background: url("../img/icon.svg") no-repeat;
                background-size: contain;
                transition: all 0.25s ease;
                top: 0;
                bottom: 0;
                right: 6.5px;
                margin: auto;
              }

              // .load_icon {
              //   position: relative;
              //   float: right;
              //   width: 60px;
              //   height: 35px;
              //   background: url("../assets/img/icon.svg") no-repeat;
              //   background-size: contain;
              //   transition: all 0.25s ease;
              // }

              .move_icon {
                display: none;
              }
            }

            .doing .move_icon {
              display: block;
              position: relative;
              float: right;
              width: 60px;
              height: 33px;

              &:before,
              &::after {
                content: "";
                display: block;
                position: absolute;
                width: 21px;
                height: 26px;
                animation:part-anim 0.8s cubic-bezier(0.445, 0.050, 0.550, 0.950) infinite alternate;

              }
              &:before {
                animation-direction:alternate-reverse;
                background: url("../img/icon-yellow.svg") no-repeat;
                top: 23px;
                left: 15px;
                background-size: contain;
              }

              &::after {
                background: url("../img/icon-red.svg") no-repeat;
                background-size: contain;
                top: 17px;
                left: 15px;
              }

              @keyframes part-anim{
                0%{
                  transform:translate3d(-10px,0,0);
                }
                100%{
                  transform:translate3d(10px,0,0);
                }
              }
            }

            .doing .load_icon {
              display: none;
            }
          }

          .nothing {
            margin: 60px 0 130px;
            display: none;
            font-size: 16px;
            font-weight: bold;
          }

          .active {
            display: block;
          }

          .result {
            display: block;
            margin: 50px 0 0;

            .area {
              width: 100%;
              margin: 0 0 20px;
              text-align: left;
              position: relative;

              &::after {
                content: '';
                position: absolute;
                width: 10px;
                height: calc(100% - 45px);
                background: #efefef;
                top: 45px;
                left: 0;
              }

              &:last-child {
                margin: 0;
              }

              h3 {
                font-size: 16px;
                font-weight: bold;
                color: #333;
                display: block;
                margin: 0 0 20px;
              }

              .result_area {
                background: #fff;
                position: relative;
                padding: 0 20px;

                .omit {
                  height: 21px;
                  background: #efefef;
                }

                .wrap {
                  display: flex;
                  margin: 2px 0;
                  min-height: 20px;

                  &::before,
                  &::after {
                    content: none;
                  }

                  p {
                    font-size: 13px;
                    margin: 0 0 0 5px;
                    white-space: pre-wrap;
                    word-wrap: break-word;
                    overflow: auto;
                  }
                  .number {
                    min-width: 45px;
                    text-align: right;
                    padding: 0 10px 0 0;
                    margin: 0;
                    position: relative;
                    color: #c1c1c1;

                    div {
                      margin: 0;
                      display: inline-block;
                      color: #777;
                    }
                  }
                }

                .deleted {
                  text-decoration: none;
                  font-weight: bold;
                  position: relative;
                  z-index: 0;

                  &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    left: 0;
                    right: 0;
                    top: 2px;
                    bottom: 2px;
                    background: rgba(244, 91, 91, 0.2);
                    z-index: -1;
                  }
                }

                .added {
                  text-decoration: none;
                  font-weight: bold;
                  position: relative;
                  z-index: 0;

                  &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    left: 0;
                    right: 0;
                    top: 2px;
                    bottom: 2px;
                    background: rgba(86, 183, 196, 0.2);
                    z-index: -1;
                  }
                }

                .has_diff {
                  text-decoration: none;
                  font-weight: bold;
                  position: relative;
                  z-index: 0;

                  .wrap {
                    display: flex;
                  }

                  &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background: rgba(255, 186, 0, 0.2);
                    z-index: -1;
                  }
                }

                ins,
                del {
                  text-decoration: none;
                  font-weight: bold;
                  z-index: 0;
                  display: inline;
                  background: rgba(255, 186, 0, 0.6);
                }
              }
            }
          }

          .share {
            width: 128px;
            margin: 50px auto;

            p {
              font-size: 16px;
              color: #333;
              font-weight: bold;
              text-align: center;
              margin: 0 0 20px;
              position: relative;

              &:before {
                content: '';
                position: absolute;
                display: block;
                background: #333;
                width: 2px;
                height: 24px;
                top: 0;
                left: 0;
                transform: rotate(-25deg);
              }

              &::after {
                content: '';
                position: absolute;
                display: block;
                background: #333;
                width: 2px;
                height: 24px;
                top: 0;
                right: 5px;
                transform: rotate(25deg);
              }
            }

            .btn {
              display: flex;
              width: 116px;
              margin: 0 auto;
              justify-content: space-between;

              a {
                width: 48px;
                height: 48px;
                background: #fff;
                border-radius: 50%;
              }

              .twitter {
                position: relative;
                .active-tw {
                  position: absolute;
                  left: 0;
                  top: 0;
                  opacity: 0;
                  transition: 0.5s;
                }

                &:hover {
                  .active-tw {
                    opacity: 1;
                  }
                }
              }
              .facebook {
                position: relative;
                .active-fb {
                  position: absolute;
                  left: 0;
                  top: 0;
                  opacity: 0;
                  transition: 0.5s;
                }

                &:hover {
                  .active-fb {
                    opacity: 1;
                  }
                }
              }
            }
          }

          .ad-box {
            margin: 50px auto;
            max-width: 100%;
            overflow: hidden;
          }

          .copyright {
            text-align: center;
            margin: 75px 0 0;
            font-size: 10px;
          }
        }
      }
    }
  }

  .fixed {
    position: fixed;
  }
}

@media print {
  body {
    margin: 0;
    padding: 0;
    font-family: 'IBM Plex Sans JP',sans-serif;

    .wrap {
      position: relative;
      margin: 0 auto;
      min-height: 100vh;

      &:before {
        content: none;
      }

      &::after {
        content: none;
      }

      .bg {
        display: none;
      }

      .bg_top {
        width: 100%;
        min-height: 100vh;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 1;

        &:before {
          content: none;
        }

        &::after {
          content: none;
        }
      }

      .inner {
        padding: 10px;
        margin: 0 auto;
        position: relative;
        z-index: 3;

        .info {
          display: none;
        }

        .info-detail {
          display: none;
        }

        .mv {
          max-width: 150px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .logo {
            margin: 0 25px 0 0;

            img {
              width: 100%;
            }
          }

          .text {
            p {
              display: none;
            }
          }
        }

        form {
          margin: 0;
          padding: 0;
          text-align: center;

          .text_area {
            margin: -25px 0 0;
            display: flex;

            .area {
              width: 50%;
              margin: 0 20px 0 0;
              text-align: left;
              height: auto;
              overflow: auto;

              &:last-child {
                margin: 0;
              }

              textarea {
                display: none;
              }

              .print_text1,
              .print_text2 {
                display: block;
                width: 100%;
                padding: 15px;
                font-family: inherit;
                font-size: 13px;
                border: 10px solid #efefef;
                box-sizing:border-box;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
              }
            }
          }

          .button {
            display: none;
          }

          .nothing {
            margin: 60px 0 130px;
            display: none;
            font-size: 16px;
            font-weight: bold;
          }

          .active {
            display: block;
          }

          .result {
            display: flex;
            margin: 50px 0 0;

            .area {
              width: 50%;
              margin: 0 20px 0 0;
              text-align: left;
              position: relative;

              &::after {
                content: '';
                position: absolute;
                width: 10px;
                height: calc(100% - 45px);
                background: #efefef;
                top: 45px;
                left: 0;
              }

              &:last-child {
                margin: 0;
              }

              h3 {
                font-size: 16px;
                font-weight: bold;
                color: #333;
                display: block;
                margin: 0 0 20px;
              }

              .result_area {
                background: #fff;
                position: relative;
                padding: 0 20px;

                .omit {
                  height: 21px;
                  background: #efefef;
                }

                .wrap {
                  display: flex;
                  margin: 2px 0;
                  min-height: 20px;

                  &::before,
                  &::after {
                    content: none;
                  }

                  p {
                    font-size: 13px;
                    margin: 0 0 0 5px;
                  }
                  .number {
                    min-width: 45px;
                    text-align: right;
                    padding: 0 10px 0 0;
                    margin: 0;
                    position: relative;
                    color: #c1c1c1;

                    div {
                      margin: 0;
                      display: inline-block;
                      color: #777;
                    }
                  }
                }

                .deleted {
                  text-decoration: none;
                  font-weight: bold;
                  position: relative;
                  z-index: 0;

                  &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    left: 0;
                    right: 0;
                    top: 2px;
                    bottom: 2px;
                    background: rgba(244, 91, 91, 0.2);
                    z-index: -1;
                  }
                }

                .added {
                  text-decoration: none;
                  font-weight: bold;
                  position: relative;
                  z-index: 0;

                  &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    left: 0;
                    right: 0;
                    top: 2px;
                    bottom: 2px;
                    background: rgba(86, 183, 196, 0.2);
                    z-index: -1;
                  }
                }

                .has_diff {
                  text-decoration: none;
                  font-weight: bold;
                  position: relative;
                  z-index: 0;

                  .wrap {
                    display: flex;
                  }

                  &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    left: 0;
                    right: 0;
                    top: 2px;
                    bottom: 2px;
                    background: rgba(255, 186, 0, 0.2);
                    z-index: -1;
                  }
                }

                ins,
                del {
                  text-decoration: none;
                  font-weight: bold;
                  z-index: 0;
                  display: inline;
                  background: rgba(255, 186, 0, 0.6);
                }
              }
            }
          }

          .share {
            display: none;
          }

          .ad-box {
            display: none;
          }

          .copyright {
            text-align: center;
            margin: 75px 0 0;
            font-size: 10px;
          }
        }
      }
    }
  }
}

@media print and (orientation: landscape) {
  body {
    .wrap {
      .inner {
        form {
          .text_area {
            margin: 25px 0 0;
          }
        }
      }
    }
  }
}
